import * as React from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/system'
import Link from '../common/Link'

const Item = styled(Link)(({ theme }) => ({
  color: theme.palette.footer.contrastText,
  cursor: 'pointer',
  margin: '0 10px 0 10px',
  textDecoration: 'none',
  fontWeight: 700,
  position: 'relative',
  transition: 'all .2s ease-in-out',
  '&:hover': {
    textDecoration: 'none !important',
    textDecorationColor: 'none',
    color: '#fff',
    '&:before': {
      width: 'calc(100% - 24px)'
    },
    '&:last-child:before': {
      width: '100%'
    }
  },
  '&:after': {
    content: '"|"',
    marginLeft: 20,
    color: theme.palette.footer.contrastText
  },
  '&:last-child:after': {
    display: 'none'
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: '-5px',
    width: 0,
    height: 2,
    transform: 'translateZ(0)',
    transition: 'width 0.2s ease',
    backgroundColor: '#fff'
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: 10,
    '&:before': {
      width: '100%',
      display: 'none'
    },
    '&:after': {
      display: 'none'
    }
  }
}))

export default function FooterMenu() {
  return (
    <>
      <Box
        component="nav"
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          mb: 2
        }}
      >
        <Item passhref="true" href="/">
          Home
        </Item>
        <Item passhref="true" href="/about-us">
          About
        </Item>
        <Item
          passhref="true"
          href="https://www.indiegogo.com/projects/ai-personal-trainer-powered-by-movementos/"
        >
          Shop Altis
        </Item>
        <Item passhref="true" href="/help-and-support">
          Help & Support
        </Item>
        <Item passhref="true" href="/terms-of-use">
          Terms of Use
        </Item>
        <Item passhref="true" href="/terms-of-purchase">
          Terms of Purchase
        </Item>
        <Item passhref="true" href="/privacy-policy">
          Privacy Policy
        </Item>
      </Box>
    </>
  )
}
