import { Container, Box } from '@mui/material'
import Logo from '../components/Logo'
import { HeaderReserveInviteButton } from '../../common/ReserveInviteButton'
import HeaderMenuItem from '../components/HeaderMenuItem'


const DesktopHeader = ({
  menuOptions
}) => {
  return <Container
    maxWidth="xl"
    component="main"
    sx={{
      py: 2,
      height: '74px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}
  >
    <Logo />
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      left: 0,
      transition: '0.2s ease'
    }}>
      {menuOptions.map(option =>
        <HeaderMenuItem
          key={'header' + option.name.toLowerCase()}
          href={option.url}
        >
          {option.name}
        </HeaderMenuItem>
      )}
      <Box
        sx={{
          transition: 'max-width 1s ease'
        }}

      >
        <HeaderReserveInviteButton isVisible={true} />
      </Box>
    </Box>

  </Container>
}
export default DesktopHeader