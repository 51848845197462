import React, { useState } from 'react'
import { styled } from '@mui/system'
import { Container } from '@mui/material'
import { Box } from '@mui/material'
import Logo from '../components/Logo'
import MobileLogo from '../components/MobileLogo'
import { HeaderReserveInviteButton } from '../../common/ReserveInviteButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import { motion, AnimatePresence } from 'framer-motion'
import HeaderMenuItem from '../components/HeaderMenuItem'

const MobileMenu = styled(motion.div)({
  paddingTop: 74,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  zIndex: '-1',
  backgroundColor: '#010040',
  left: 0,
  right: 0,
  alignItems: 'center',
  position: 'absolute',
  top: '-100%',
  opacity: 0
})

const MobileHeader = ({ isVisible, menuOptions }) => {
  const matchesSmallScreen = useMediaQuery('(min-width: 450px)')
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <Container
      component="main"
      sx={{
        p: '0 16px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}
    >
      <Container
        sx={{
          p: 0,
          height: '74px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {matchesSmallScreen ? <Logo /> : <MobileLogo />}

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            pr: '20px'
          }}
        >
          <HeaderReserveInviteButton isVisible={isVisible} />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="19"
            viewBox="0 0 29 19"
            fill="none"
          >
            <rect width="29" height="3" rx="1.5" fill="white" />
            <rect y="8" width="29" height="3" rx="1.5" fill="white" />
            <rect y="16" width="29" height="3" rx="1.5" fill="white" />
          </svg>
        </Box>
      </Container>
      <AnimatePresence>
        {isMenuOpen && (
          <MobileMenu
            key="mobileMenu"
            initial={{ top: '-100%', opacity: 0 }}
            animate={{ top: '0', opacity: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ top: '-100%', opacity: 0 }}
          >
            {menuOptions.map((option) => (
              <Box m={2} key={`mobile-header-` + option.name}>
                <HeaderMenuItem
                  key={'header-mobile-' + option.name.toLowerCase()}
                  href={option.url}
                >
                  {option.name}
                </HeaderMenuItem>
              </Box>
            ))}
          </MobileMenu>
        )}
      </AnimatePresence>
    </Container>
  )
}
export default MobileHeader
