import React from 'react'
import { styled } from '@mui/system'
import { AppBar, Container, useScrollTrigger } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

import DesktopHeader from './DesktopHeader'
import MobileHeader from './MobileHeader'

const HeaderWrap = styled(AppBar)(({ theme }) => ({
  background: 'transparent',
  boxShadow: 0,
  transition: 'all .3s ease-in-out',
  '&.MuiPaper-elevation0': {
    background: 'transparent'
  },
  '&.MuiPaper-elevation4': {
    background: theme.palette.header.main
  }
}))

function ElevationScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  })
}

const menuOptions = [
  {
    name: 'Home',
    url: '/'
  },
  {
    name: 'About',
    url: '/about-us'
  },
  {
    name: 'What’s New',
    url: '/blog'
  }
]

export default function Header(props) {
  const matchesSmallScreen = useMediaQuery('(max-width: 600px)')
  return (
    <ElevationScroll {...props}>
      <HeaderWrap position="fixed" sx={{ boxShadow: 0 }}>
        {matchesSmallScreen ? (
          <MobileHeader
            menuOptions={menuOptions}
            isVisible={!props.isCTAButtonVisible}
          />
        ) : (
          <DesktopHeader
            menuOptions={menuOptions}
            isVisible={!props.isCTAButtonVisible}
          />
        )}
      </HeaderWrap>
    </ElevationScroll>
  )
}
